'use strict'

const CONSTANTS = require('../constants/constants');

/**
 * This class contains all base Analytics events.
 * Use this one as base class for the other concrete builders.
 */
class BaseAnalyticsBuilder{

  /**
   * ContextUrl -- URL to call to retrieve all context data for event pushing
   * ContextData -- Object that contains all context data to push
   */
  constructor() {
    this.contextData = {}
    this.contextUrl = $('body').data('contextevent');
    this.detailViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    const EventHelper = require('../helpers/eventHelper');
    const CarouselEventHelper = require('../helpers/carouselEventHelper');
    const PaymentEventHelper = require('../helpers/paymentEventHelper');

    var currentBody = $('body');

    /**
     * Attach generic events using EventHelper
     */
    $(document).on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.MINICART_REMOVE_PRODUCT, EventHelper.removeProduct);

    $('.js-tile').on('click', {url : $('body .js-ga4-dataset').data('viewevent')}, EventHelper.clickTile);
    // $('.slick-slide .quickview').on('click', {url : $('body .js-ga4-dataset').data('viewevent')}, (e) => {
    //   var $current = $(e.currentTarget);
    //   var variationData = $current.closest('.product')
    //                            .find('.data-itemid')
    //                            .data('variation');
    //   EventHelper.clickEditorialTile(e, variationData);
    // });
    $('.edit-add-to-wishlist .js-edit-product-modal').on('click', { url: $('body .js-ga4-dataset').data('viewevent') }, (e) => { EventHelper.clickTile(e, true) });

    /**
     * Attach specific payment method events using PaymentEventHelper
     */
    $('.js-minicart').on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.MINICART_EXPRESS, PaymentEventHelper.clickPayPalExpress);


    /**
     * Attach carousel events using CarouselEventHelper
     */
    $(document).on(CONSTANTS.EVENT_CONSTANTS.GENERAL.OBSERVE_CAROUSEL, CarouselEventHelper.attachObserverCarousel);


    // $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.ADD_PRODUCT, {url : this.detailViewEventUrl}, addProduct);



    /**
     * Attach notification events
     */
    attachNotificationEvents();
  }
}


/**
 * Private function that handles notification events attach
 */
function attachNotificationEvents(){
  const NotificationEventHelper = require('../helpers/notificationEventHelper');

  // Click on the notifications icon
  $('.user-notification').on('click', '.js-analytics-icon-notification, .js-bell-trigger', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.CTA_ICON_NOTICES);
  });

  // Click on the notification coupon
  document.addEventListener('couponClicked', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.COUPON);
  });

  // Click on the notification wishlist
  document.addEventListener('wishlistClicked', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.WISHLIST);
  });

  // Click My Account coupon selection
  document.addEventListener('couponClickedNotices', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.COUPON);
  });

  // Click modal promo notification wishlist
  $('.promo-modal-bottom').on('click', '.js-promo-session', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.WISHLIST_PROMO);
  });

  // Click My Account header
  $('.js-analytics-myaccount').on('click', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.MYACCOUNT_OPEN);
  });
}

function addProduct(event, data){

  if(typeof dataLayer != 'undefined'){
        
    let url = $('.js-ga4-dataset').data('addproducturl');
  
    //STEP 1: Update cart
    $.ajax({
      type: 'POST',
      url: url + '?detailaddtocart=true',
      success: function (data) {
        //STEP 1: Check data existence
        if (!data.analyticsData){
          console.error('PDP add to card failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push(data.analyticsData);
      },
    });
  }
}

module.exports = BaseAnalyticsBuilder;
