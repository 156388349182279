'use strict'

const CONSTANTS = require('../constants/constants');
const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

class DetailsAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    this.detailViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach all detail page triggers
    this.attachSpecificTriggers();
  }


  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.ADD_PRODUCT, {url : this.detailViewEventUrl}, addProduct);
    $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.SELECT_SIZE, {url : this.detailViewEventUrl}, selectSizeEvent);
    $(document).on('click', '.swatch-circle', pushEventNameOnly);
    // $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.SELECT_IMAGE, {name : CONSTANTS.EVENT_LIST_NAME.SELECT_IMAGE}, pushEventNameOnly);
    $('.js-zoom-image').on('click', {name : CONSTANTS.EVENT_LIST_NAME.SELECT_IMAGE}, pushEventNameOnly);
    $(document).on('click', '#productAdditionalInfo > div', detailSelection);
    viewProductEvent(this.detailViewEventUrl)
  }
}

/**
 * PRIVATE FUNCTIONS
 */

/**
 * Remove product event function
 */
function addProduct(event, data){

  if(typeof dataLayer != 'undefined'){
        
    let url = $('.detail-items-form-wrap').data('url');
  
    //STEP 1: Update cart
    $.ajax({
      type: 'POST',
      url: url + '?detailaddtocart=true',
      success: function (data) {
        //STEP 1: Check data existence
        if (!data.analyticsData){
          console.error('PDP add to card failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push(data.analyticsData);
      },
    });
  }
}


/**
 * View PDP product event
 * @param event
 */
function viewProductEvent(url){

  if(typeof dataLayer != 'undefined'){
        
    let color = $('.color-attribute .selected').data('attr-value');
    let size = $('.size-attribute .selected').data('attr-value');
  
    let product = `${color.split('-')[0]}|${color.split('-')[1]}|${size}`
  
    url = url.split('productString')[0] + 'productString=' + product;
  
    //STEP 1: Update cart
    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        //STEP 1: Check data existence
        if (!data || !data.eventData){
          console.error('PDP view analytics failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push({
          event : CONSTANTS.EVENT_LIST_NAME.VIEW_PRODUCT,
          ecommerce : data.eventData
        });
      },
    });
  }
}


/**
 * Size selection event push
 * @param event
 * @returns {boolean}
 */
function selectSizeEvent(event){

  if(typeof dataLayer != 'undefined'){
        
    event.preventDefault();
  
    let url = event.data.url
  
    let variant = {}
    if(sessionStorage.getItem('selectSizeEvent')){
      variant = JSON.parse(sessionStorage.getItem('selectSizeEvent'));
      let color = variant.color.split('-')[0] + '|' + variant.color.split('-')[1]
      url += `&variant=${color}|${variant.size}`
    }
  
  
    //STEP 1: Update cart
    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        //STEP 1: Check data existence
        if (!data || !data.eventData){
          console.error('Product size view analytics failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push({
          event : CONSTANTS.EVENT_LIST_NAME.SELECT_SIZE,
          ecommerce : data.eventData
        });
  
      },
    });
  }
}


/**
 * Function used for name only events
 * Event name will be retrived by element class name
 * @returns {boolean}
 */
function pushEventNameOnly(event){

  if(typeof dataLayer != 'undefined'){
        
    //STEP 1: Check event name
    var name;
    if(event.data && event.data.name && event.data.name !== '')
      name = event.data.name;
    else if($(this).hasClass('swatch-circle'))
      name = CONSTANTS.EVENT_LIST_NAME.SELECT_SIZE;
  
  
    if(!name){
      console.error('Impossible to send GA4 event: name is missing');
      return false;
    }
  
    //STEP 2: Push event name
    dataLayer.push({
      event : name
    });
  }

}


/**
 * Detail selection push
 * @returns {boolean}
 */
function detailSelection(){

  if(typeof dataLayer != 'undefined'){
        
    //STEP 1: Check asset value
    var assetName = $(this).attr('id');
    if(!assetName){
      console.error('Impossible to send GA4 details click: missing asset');
      return false;
    }
  
    var assetValue = CONSTANTS.EVENT_CONSTANTS.PDP.ASSETS[assetName];
  
    if(!assetValue) {
      console.error('No values found for current asset: failed to push detail event');
      return false;
    }
  
    //STEP 2: Push event name
    dataLayer.push({
      event : CONSTANTS.EVENT_LIST_NAME.SELECT_DETAILS,
      detail_type : assetValue
    });
  }
}

module.exports = DetailsAnalyticsBuilder;
