'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');
const CONSTANTS = require('../constants/constants');

class CartAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
    this.cartViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
    //Get all page elements who are useful for Google Analytics
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach specific events
    this.attachSpecificTriggers();
  }

  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    viewCartEvent(this.cartViewEventUrl);
    $('#update-cart').on('click', updateCartEvent); //UPDATE CART EVENT
    $('.cart-item-actions .button-text').on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.REMOVE_PRODUCT, updateCartEvent); //DELETE FROM CART EVENT
  }
}

/**
 * Event functions
 */

/**
 * Update cart event
 * @param event
 */
function updateCartEvent(event, data){

  if(typeof dataLayer != 'undefined'){
        
    let updateForm = $('#cart-items-form-wrap');
    let url;
  
    if(data.hasOwnProperty('increase')){
      url = updateForm.data('url') + '?isincrease=' + data.increase
    }
  
    if(data.hasOwnProperty('action') && data.hasOwnProperty('pid')){
      url = updateForm.data('url') + '?action=' + data.action + '&removepid=' + data.pid;
    }
  
    //STEP 1 -- Update cart
    $.ajax({
      type: 'POST',
      url: url,
      // data: updateForm.serialize(),
      success: function (data) {
        //STEP 1: Check data existence
        if (!data){
          console.error('Cart update analytics failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push({
          event : data.analyticsData.event,
          ecommerce : data.analyticsData.ecommerce
        });
      },
    })
  }

}


/**
 * Delete cart event
 * @param event
 */
function deleteCartEvent(event){

  if(typeof dataLayer != 'undefined'){
        
    const util = require('../util');
  
    event.preventDefault();
    let updateForm = $('#cart-items-form-wrap');
    let productToRemove = $('#cart-items-form-wrap').data('pid');
  
    //STEP 1 -- Update cart
    $.ajax({
      type: 'POST',
      url: util.ajaxUrl(updateForm.attr('action')) + '&action=' + event.data.action + '&removepid=' + productToRemove,
      data: updateForm.serialize(),
      success: function (data) {
        //STEP 1: Check data existence
        if (!data){
          console.error('Cart update analytics failed');
          return;
        }
  
        //STEP 2 -- Push data to analytics
        for (let key in data.analyticsData){
          let currentEvent = data.analyticsData.hasOwnProperty(key) ? data.analyticsData[key] : false;
          if(currentEvent)
            dataLayer.push(currentEvent)
        }
  
        //STEP 3 -- Redirect to final location
        location.href = data.redirectUrl;
      },
    })
  }
}


/**
 * View cart event
 * @param event
 */
function viewCartEvent(url){

  if(typeof dataLayer != 'undefined'){
        
    // event.preventDefault();
  
    //STEP 1: Update cart
    $.ajax({
      type: 'GET',
      url: url,
      success: function (data) {
        //STEP 1: Check data existence
        if (!data || !data.eventData){
          console.error('Cart view analytics failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push({
          event : CONSTANTS.EVENT_LIST_NAME.VIEW_CART,
          ecommerce : data.eventData
        });
      },
    });
  }
}

module.exports = CartAnalyticsBuilder;
