'use strict'

const BaseAnalyticsBuilder = require('./baseAnalyticsBuilder');

const CONSTANTS = require('../constants/constants');

class ListingAnalyticsBuilder extends BaseAnalyticsBuilder{

  constructor() {
    super();
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    //Attach base events
    super.attachAnalytics();

    //Attach all detail page triggers
    this.attachSpecificTriggers();
  }

  /**
   * This function exposes triggers to element inside current page
   */
  attachSpecificTriggers(){
    $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.ADD_PRODUCT, {url : this.detailViewEventUrl}, addProduct);

    $(document).on(CONSTANTS.EVENT_CONSTANTS.PLP.EVENTS.FIRE_SEARCH,  {checkPage : true}, viewSearchList);
    $(document).on(CONSTANTS.EVENT_CONSTANTS.PLP.EVENTS.FIRE_FIRST_SEARCH,  {checkPage : false}, viewSearchList);

    //Trigger first search view event
    $(document).trigger(CONSTANTS.EVENT_CONSTANTS.PLP.EVENTS.FIRE_FIRST_SEARCH);

    //Filters event
    $(document).on(CONSTANTS.EVENT_CONSTANTS.PLP.EVENTS.FIRE_FILTERS, useFilter);

    /* INCLUSI IN MODO DINAMICO NELL'EVENTO "useFilter" */
    // $(document).on(CONSTANTS.EVENT_CONSTANTS.PLP.EVENTS.FIRE_FILTERS_REMOVE, removeAllFilters);
    // $(document).on('click', '.js-button-update-count .reset', removeAllFilters);
    // $(document).on('click', '.js-filter-applied-element', removeFilter);
  }

}

/**
 * Private functions
 */

function addProduct(event, data){

  if(typeof dataLayer != 'undefined'){
        
    let url = $('.detail-items-form-wrap').data('url');
  
    //STEP 1: Update cart
    $.ajax({
      type: 'POST',
      url: url + '?detailaddtocart=true',
      success: function (data) {
        //STEP 1: Check data existence
        if (!data.analyticsData){
          console.error('PLP add to card failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        dataLayer.push(data.analyticsData);
      },
    });
  }
}


/**
 * Function that handles listing view event push.
 * Each view element must contain a maximum of 10 elements.
 * If more elements are retrieved this function will split event push in smaller events.
 * @returns {boolean}
 */
function viewSearchList(event){
  //STEP 0 -- Look for core data inside grid
  var grid = $('.search-result-items');
  var analyticsUrl = grid.data('analytics');
  if(!analyticsUrl || analyticsUrl === '')
    return false;

  //STEP 1 -- Check if current grid has ever pushed analytics
  var isFirstSearch = grid.data('first-search-done');

  //STEP 2 -- Get search elements
  var searchElements;
  if(!isFirstSearch)
    searchElements = $('.grid-tile-v2');
  else {
    var lastIndex = $('.last-index').last();
    searchElements = lastIndex.prevUntil('.last-index');
  }

  //STEP 3 -- Build PID list: get only first product to avoid URL Quota
  var productString;
  var processedProducts = [];
  searchElements.each(function(element){
    var insideTileBlock = $(this).find('.product-tile-v2');
    if (insideTileBlock.length < 1)
      return;

    //STEP 3.A -- Get product master
    var master = insideTileBlock.data('itemid');

    //STEP 3.B -- Get valid colors
    var colors = $(this).data('colors-to-show');

    if(!colors || colors == '' || !master || master === '')
      return;

    //STEP 3.C -- Compose product string by attaching color to master. This can avoid master with different id
    processedProducts.push(master + '|' + colors.split(',')[0].split('-')[1]);
  });
  productString = processedProducts.join(',');

  if(!productString){
    console.error('No data for analytics listing');
    return false;
  }

  const EventHelper = require('../helpers/eventHelper');
  var currentPage;
  if(event.data && event.data.checkPage)
    currentPage = parseInt(EventHelper.getCurrentPage(), 10) + 1;

  //STEP 4 -- Call route to get analytics data
  $.ajax({
    type: 'GET',
    url: analyticsUrl + '&productString=' + productString + (currentPage ? '&page=' + currentPage : ''),
    success: function (data) {
      //STEP 5: Check data existence
      if (!data || !data.eventData){
        console.error('List view analytics failed');
        return;
      }

      //STEP 6: Build and push data to analytics
      EventHelper.pushViewListEvent(data);

      //STEP 7: Setup analytics
      grid.data('first-search-done', true);
    },
  });
}


/**
 * Internal filter push function
 * @param element
 * @param type
 */
function pushFilterEvent(element, type){
  if(typeof dataLayer != 'undefined'){
        
    dataLayer.push({
      event : CONSTANTS.EVENT_LIST_NAME.USE_FILTER.BASE,
      filter_applied : element,
      filter_action : type
    });
  }
}


/**
 * This function handles filter usage event.
 * Trigger this event after BE successful response.
 * WARNING: if filter is added without FE interaction, this function will ignore it
 * @param event
 * @param data
 */
function useFilter(event, data){

  if(data && data.addedFilters){
    var addedFilters = data.addedFilters;
    if(addedFilters.length > 0){
      addedFilters.forEach(function(element){
        pushFilterEvent(element, CONSTANTS.EVENT_LIST_NAME.USE_FILTER.ADD);
      })
    }
  }

  if(data && data.removedFilters){
    var removedFilters = data.removedFilters;
    if(removedFilters.length > 0){
      removedFilters.forEach(function(element){
        pushFilterEvent(element, CONSTANTS.EVENT_LIST_NAME.USE_FILTER.REMOVE);
      })
    }
  }
}


/**
 * This function handles 'delete all filters' click by pushing GA4 events before redirecting.
 * @param event
 * @param data
 */
function removeAllFilters(event, data){
  event.preventDefault();

  //STEP 1 -- Get previous applied filters
  var previousFilters = [];
  var filterContainer = $('.js-filter-applied-element');
  filterContainer.each(function(){
    var filterValue = $(this).data('value');
    if(filterValue)
      previousFilters.push(filterValue);
  });

  previousFilters.forEach(function(element){
    pushFilterEvent(element, CONSTANTS.EVENT_LIST_NAME.USE_FILTER.REMOVE);
  });

  // window.location.href = data && data.url ? data.url : $(this).find('a').attr('href');
}

/**
 * When a filter breadcrumb is pressed, remove the filter
 * @param event
 */
function removeFilter(event){
  pushFilterEvent($(this).data('value'), CONSTANTS.EVENT_LIST_NAME.USE_FILTER.REMOVE);

  window.location.href = $(this).attr('href');
}

module.exports = ListingAnalyticsBuilder;
